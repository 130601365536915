.calendar-container {
  margin: 0 auto;
  margin-top: 1rem;
  border: 1px solid;

  .card {
    background-color: #fdfefe !important;

    .dropdowns-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      padding: 0.5rem;
    }

    .days-container {
      background-color: #fff;
      // border: 2px solid #222222;
      // border-radius: 5px;
      display: grid;
      grid-template-columns: 12.5rem auto;
      // max-height: 500px;
      overflow-y: auto;

      .property-wrapper {
        padding: 0.5rem 0.2rem 0.5rem 0rem;

        .property-header {
        }
        .property-list {
          margin-top: 0.4rem;
          overflow: hidden;
          .property-name {
            background-color: rgb(85, 124, 255);
            border-bottom: 1px solid white;
            height: 23px;
            padding: 2px;
            color: white;
            width: 12.3rem;
            font-weight: 500;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .days-wrapper {
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;

        .list-wrapper {
          display: flex;
          .today {
            background-color: #444444 !important;
            color: #e7e7e7 !important;

            .day-name {
              color: #e7e7e7 !important;
            }
          }

          .dates-list {
            position: relative;
            background-color: #d1d1d1;
            border: 1px solid #9b8f8f;
            min-width: 2.83rem;
            text-align: center;

            .day-name {
              color: #161616;
              font-size: 10px;
              font-weight: 700;
            }

            .date {
              font-weight: bold;
              font-size: 14px;
            }

            &:hover {
              transition: 0.2s ease-in-out;
              border: 1px solid #000 !important;
            }
          }
        }

        .activity-row {
          display: table-cell;

          .activity {
            position: relative;

            .day-rows {
              display: flex;

              .dates-list {
                padding: 0.5px;
                border: 1px solid #d1d1d1;
                height: 23px;
                min-width: 2.83rem;
                text-align: center;
              }
            }
            .duration-wrapper {
              .duration {
                position: absolute;
                top: 2px;
                height: 18px;
                color: white;
                display: flex;
                font-size: 12px;
                justify-content: space-around;
                align-items: center;
                transition: 0.1s ease-in-out;
                transform: skew(-35deg);

                &:hover {
                  transition: 0.1s ease-in-out;
                  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
                }

                .vendor-logo {
                  position: absolute;
                  display: none;
                  transform: skew(35deg);
                  background-color: #fff;
                  border-radius: 50%;
                  height: 15px;
                  width: 15px;
                  img {
                    padding: 5px;
                    height: 15px;
                  }
                }

                .name-pay {
                  position: absolute;
                  transform: skew(35deg);
                  left: 5px;

                  .amount {
                    font-weight: 700;
                  }
                }
                .booking-details {
                  display: flex;
                  flex-direction: column;

                  .guest_from {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #ddd;
                    color: black;
                    font-size: 10px;
                    font-weight: 700;
                    border-radius: 10px;
                  }
                }
                .call-btn {
                  background-color: #fff;
                  border-radius: 50%;
                  height: 35px;
                  min-width: auto !important;

                  .call-icon {
                    height: 22px;
                  }
                }
              }
              .d-none {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.dropdowns-wrapper {
  margin-bottom: 1rem;
}

.dropdown-width {
  width: 8rem;
}

.duration::after {
  top: 8px;
  height: 45px;
  transform: skew(-45deg);
}
