body {
  background-color: white;
}

.ant-btn-primary {
  background-color: #557cff !important;
}

// gloabl css--------------
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}

// Header css ----------

header {
  padding: 0px !important;
}

.header {
  padding: 0px;

  .header-items {
    float: right;
  }
}

.header-dashboard {
  background-color: white;
  display: flex;
  align-items: center;
}

.logo {
  height: 32px;
  background: white;
  margin: 16px;
}

.sidebar {
  background-color: white;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

th .table,
td .table {
  text-align: center;
}

.success_btn {
  color: green;
  :hover {
    background-color: green;
    border: green;
  }
}

.col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid;
  padding: 2px 10px;
  margin-top: 0.3rem;
  h1 {
    color: black !important;
    margin-bottom: 0;
    font-size: 15px;
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
  }
}

//QuickListings Styles ------------------
.quick-listing {
  background-color: #fdfefe;
  padding: 0.3rem 0.5rem;

  &-search-field {
    margin-bottom: 0.6rem;
  }

  &-content {
    .badge {
      background: #557cff;
      color: white;
      padding: 0.2rem 0.5rem;
    }
    .next-booked {
      display: flex;
      flex-direction: column;
      background: #557cff;
      color: white;
      padding: 0.3rem 0.5rem;
      width: 14rem;
      margin-top: 0.5rem;
      h3,
      p {
        margin: 0;
      }
    }

    .place-type {
      &-badge {
        background: #ddd;
        padding: 0.3rem 0.6rem;
        border-radius: 3px;
        margin-top: 0.5rem;
      }
    }
    .place-btn-group-btn {
      margin-top: 0.5rem;
    }
  }
  .last-btn {
    margin-top: 0.5rem;
  }
  .visibility {
    border: 1px solid;
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.5rem;
  }
}

// SHorcut styles -------------
.shortcut-wrapper {
  background-color: #fdfefe;
  padding: 1rem 2rem;
}

.todo-list-wrapper {
  background-color: #fdfefe;
  padding: 1rem 2rem;
  margin-top: 1rem;
  height: 22.3rem;
  overflow: hidden;
  overflow-y: auto;
}

// GST Styles-------------

.MuiTableRow-root:nth-child(even) {
  background-color: #f2f4f4;
}

.MuiTableRow-root:hover {
  background-color: #d6eaf8;
}

.MTableHeader-header-390 {
  font-weight: 700 !important;
}

.badge {
  background-color: #e5e8e8;
  width: 5rem;
  padding: 0.2rem;
  text-align: center;
}

.danger {
  background-color: #dc3545;
  color: white;
}

.active {
  background-color: #28a745;
  color: white;
}

.rounded {
  border-radius: 5px;
}

.shadow {
  box-shadow: 1px 5px 22px -8px rgba(41, 41, 41, 1);
}

.ant-input,
.ant-input-number,
.ant-picker {
  border-color: #b9b9b9;
  outline: 0;
  box-shadow: none;
}
// .ant-input:focus {
//   border-color: #566573;
//   outline: 0;
//   box-shadow: none;
//   // color: #292929;
// }
// .ant-input:hover {
//   border-color: #566573;
//   outline: 0;
//   box-shadow: none;
// }

.ant-input::placeholder,
.ant-select::placeholder {
  color: #99a3a4;
}

// .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
//   .ant-select-selector {
//   border-color: #566573;
//   outline: 0;
//   box-shadow: none;
// }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #b9b9b9;
  outline: 0;
  box-shadow: none;
  color: #99a3a4 !important;
}

// Chat App Styling......................
.chat-app {
  .chat-members {
    .title {
      text-align: center;
      margin-top: 1.25rem;
    }
    background-color: #fff;
    &-wrapper {
      list-style-type: none;
      padding-inline-start: 0px;
      margin-block-end: 0em;
      li {
        padding: 1rem;
        border-bottom: 1px solid #d5d8dc;
        text-align: left;
        &:hover {
          background-color: #f8f9f9;
          cursor: pointer;
        }
      }
    }
  }
  .chat-window {
    .ant-card-body {
      padding: 0px;
    }
    .window-wrapper {
      padding: 1rem;
      background: #d5d8dc;
      display: flex;
      flex-direction: column;
      height: 400px;
      .chat-person-one {
        display: flex;
        justify-content: left;
        p {
          background-color: #f8f9f9;
          padding: 0.5rem;
          border-radius: 5px;
        }
      }
      .chat-person-two {
        display: flex;
        justify-content: flex-end;
        p {
          background-color: #f8f9f9;
          border-radius: 5px;
          padding: 0.5rem;
        }
      }
    }
  }
}

.bookingView-card {
  .header-btns {
    border: 1px solid;
    display: flex;
    align-items: center;
    padding: 0px;
  }

  .guest-detials,
  .roamhome-details {
    display: flex;
    align-items: flex-start;

    img {
      margin-right: 1rem;
    }

    ul {
      list-style-type: none;
      padding-inline-start: 10px;
      margin-top: 3px;
    }
  }
}

.avatar-uploader-trigger,
.avatar {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.avatar-uploader {
  display: block;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
}
.avatar-uploader-trigger {
  display: table-cell;
  vertical-align: middle;
  font-size: 28px;
  color: #999;
}

.default {
  background-color: #58d68d;
  padding: 0.3rem;
  border-radius: 5px;
  color: white;
  text-transform: capitalize;
}

.waiting {
  background-color: #eb984e !important;
}

.cancel {
  background-color: rgb(255, 123, 123) !important;
}

.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  margin: 0rem 5rem !important;
}

.ant-badge-status-dot {
  width: 10px !important;
  height: 10px !important;
}

.stepper-submit {
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0rem 1rem 0rem;
  padding: 0rem 3rem;
}

.google-places-autocomplete {
  width: 100%;
  position: relative;
}

.google-places-autocomplete__input {
  width: calc(100% - 20px);
  padding: 5px 10px;
  border: 1px solid #ddd;
  margin-bottom: 2px;
}

.google-places-autocomplete__input:active,
.google-places-autocomplete__input:focus,
.google-places-autocomplete__input:hover {
  border: 1px solid #ccc;
}

.google-places-autocomplete__suggestions-container {
  background: #fff;
  border-radius: 0 0 5px 5px;
  color: #000;
  position: absolute;
  width: 100%;
  z-index: 2;
  border: 2px solid #ddd;
}

.google-places-autocomplete__suggestion {
  font-size: 14px;
  text-align: left;
  padding: 8px;
}

.google-places-autocomplete__suggestion:hover {
  background: #eee;
  cursor: pointer;
}

.google-places-autocomplete__suggestion--active {
  background: #e0e3e7;
}

.dl-list {
  flex-wrap: wrap;
  justify-content: space-evenly;
}
