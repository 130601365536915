.day-01 {
  left: 2%;
}
.day-02 {
  left: 5.5%;
}
.day-03 {
  left: 8.75%;
}
.day-04 {
  left: 11.5%;
}
.day-05 {
  left: 14.8%;
}
.day-06 {
  left: 18%;
}
.day-07 {
  left: 21.2%;
}
.day-08 {
  left: 24.6%;
}
.day-09 {
  left: 27.8%;
}
.day-10 {
  left: 30.8%;
}
.day-11 {
  left: 34%;
}
.day-12 {
  left: 37.5%;
}
.day-13 {
  left: 41.2%;
}
.day-14 {
  left: 43.9%;
}
.day-15 {
  left: 47%;
}
.day-16 {
  left: 50.2%;
}
.day-17 {
  left: 53.6%;
}
.day-18 {
  left: 57%;
}
.day-19 {
  left: 60.4%;
}
.day-20 {
  left: 63.3%;
}
.day-21 {
  left: 66.6%;
}
.day-22 {
  left: 69.6%;
}
.day-23 {
  left: 73%;
}
.day-24 {
  left: 76.5%;
}
.day-25 {
  left: 79.6%;
}
.day-26 {
  left: 82.6%;
}
.day-27 {
  left: 85.5%;
}
.day-28 {
  left: 88.8%;
}
.day-29 {
  left: 92.2%;
}
.day-30 {
  left: 95.5%;
}
.day-31 {
  left: 98.5%;
}
