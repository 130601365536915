.dur-1 {
  width: 2.6rem;
  font-size: 8px !important;
  padding: 5px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dur-2 {
  width: 5rem;
  font-size: 12px !important;
  padding: 4.5px !important;

  // .name-pay {
  //   display: none !important;
  // }
}
.dur-3 {
  width: 8rem;
  font-size: 12px !important;
  padding: 3px !important;
}
.dur-4 {
  width: 11rem;
}
.dur-5 {
  width: 13.7rem;
}
.dur-6 {
  width: 16.5rem;
}
.dur-7 {
  width: 19.5rem;
}
.dur-8 {
  width: 22.3rem;
}
.dur-9 {
  width: 25rem;
}
.dur-10 {
  width: 27.8rem;
}
.dur-11 {
  width: 30.8rem;
}
.dur-12 {
  width: 34rem;
}
.dur-13 {
  width: 36.5rem;
}
.dur-14 {
  width: 39.2rem;
}
.dur-15 {
  width: 42rem;
}
.dur-16 {
  width: 45rem;
}
.dur-17 {
  width: 48rem;
}
.dur-18 {
  width: 51rem;
}
.dur-19 {
  width: 53.5rem;
}
.dur-20 {
  width: 56.4rem;
}
.dur-21 {
  width: 59rem;
}
.dur-22 {
  width: 62rem;
}
.dur-23 {
  width: 65rem;
}
.dur-24 {
  width: 67.8rem;
}
.dur-25 {
  width: 70.4rem;
}
.dur-26 {
  width: 73rem;
}
.dur-27 {
  width: 75.8rem;
}
.dur-28 {
  width: 78.8rem;
}
.dur-29 {
  width: 81.8rem;
}
.dur-30 {
  width: 84.5rem;
}

.Airbnb {
  background-color: #fd5c63;
}

.Bcom {
  background-color: #003580;
}

.Roamhome {
  background-color: #e34234;
}

.Agoda {
  background-color: #347fc2;
}

.MakeMyTrip {
  background-color: #164880;
}

.blocked {
  background-color: #707070;
}
